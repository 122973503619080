var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ticket\" style=\"color:black;\">\r\n    <meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\">\r\n    <span class=\"html-content\">\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"content","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </span>\r\n    <style>\r\n        .html-content {\r\n            width: 72mm;/*printer paper width*/\r\n            font-size: 1.5em;\r\n            font-family: monospace;\r\n        }\r\n        .html-content table {\r\n            width: 100%;\r\n            margin-bottom: 40px;\r\n            border-collapse: collapse;\r\n        }\r\n        .html-content table thead {\r\n            font-weight: bold;\r\n            font-size: 1.5em;\r\n        }\r\n        .html-content td {\r\n            border: 2px solid black;\r\n            font-size: 1.4em;\r\n        }\r\n        .html-content td:not(:first-child) {\r\n            width: 190px;\r\n            text-align: right;\r\n            padding-right: 2px;\r\n        }\r\n        .html-content tr td:not(:first-child) {\r\n            white-space: nowrap;\r\n        }\r\n        </style>\r\n</div>";
},"useData":true});