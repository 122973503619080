var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-light designer-box rounded\" data-action=\"print\">\r\n            <div class=\"loader-wrapper\" data-name=\"loader-print\">\r\n                <div class=\"loader\"></div>\r\n            </div>\r\n            <div data-action=\"receipt\">\r\n                <i class=\"fas fa-receipt\"></i>\r\n                <br>\r\n                <span>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":22},"end":{"line":35,"column":58}}}))
    + "</span>\r\n            </div>\r\n        </button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"payment-info-left\">\r\n    <i class=\"far fa-5x fa-check-circle\"></i><br />\r\n</div>\r\n\r\n<div class=\"payment-info-right\" data-region=\"log-items\"></div>\r\n\r\n<div class=\"btn-row\">\r\n    <button class=\"btn btn-light designer-box rounded\">\r\n        <div data-action=\"back\">\r\n            <div class=\"no-receipt-icons\">\r\n                <i class=\"fas fa-arrow-left\"></i>\r\n            </div>\r\n            <br>\r\n            <span>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":14,"column":45}}}))
    + "</span>\r\n        </div>\r\n    </button>\r\n\r\n    <button class=\"btn btn-light designer-box rounded\" data-action=\"email\">\r\n        <div class=\"loader-wrapper\" data-name=\"loader-email\">\r\n            <div class=\"loader\"></div>\r\n        </div>\r\n        <i class=\"fas fa-envelope\"></i>\r\n        <br>\r\n        <span>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"email_invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":14},"end":{"line":24,"column":50}}}))
    + "</span>\r\n    </button>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_receipt_printer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"useData":true});