var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <tr>\r\n            <td colspan=\"4\" class=\"only-orderable-warning\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"only_products_on_stock_or_backorder_are_visible_here_and_are_not_reservered_for_other_orders",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":59},"end":{"line":11,"column":174}}}))
    + "</td>\r\n        </tr>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-vcenter table-striped m-0\">\r\n    <thead>\r\n        <tr>\r\n            <th>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"product_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":51}}}))
    + "</th>\r\n            <th>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"sku",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":42}}}))
    + "</th>\r\n            <th style=\"min-width: 125px;\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"stock",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":42},"end":{"line":6,"column":70}}}))
    + "</th>\r\n            <th style=\"min-width: 75px;\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"price",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":69}}}))
    + "</th>\r\n        </tr>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"onlyOrderable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    </thead>\r\n    <tbody></tbody>\r\n</table>\r\n";
},"useData":true});