var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"repaired_item_serial_no") || (depth0 != null ? lookupProperty(depth0,"repaired_item_serial_no") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"repaired_item_serial_no","hash":{},"data":data,"loc":{"start":{"line":8,"column":78},"end":{"line":8,"column":107}}}) : helper)))
    + ")";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"date_done") : depth0),"LL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":34,"column":50}}}))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                -\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"loader-wrapper\" data-name=\"loader-print\">\r\n            <div class=\"loader\"></div>\r\n        </div>\r\n        <button class=\"btn btn-light btn-block designer-box rounded\" data-ui=\"print-receipt\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":66,"column":93},"end":{"line":66,"column":129}}}))
    + "</button>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-light btn-block designer-box rounded\" disabled>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":68,"column":78},"end":{"line":68,"column":114}}}))
    + "</button>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"customer full-child-height\" data-region=\"customer\"></div>\r\n<div class=\"description designer-box rounded p-3\">\r\n    <p class=\"mb-0\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":37}}}) : helper)))
    + "</p>\r\n    <hr class=\"mt-2 mb-3 mr-3\">\r\n    <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"extra_information") || (depth0 != null ? lookupProperty(depth0,"extra_information") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"extra_information","hash":{},"data":data,"loc":{"start":{"line":5,"column":7},"end":{"line":5,"column":30}}}) : helper)))
    + "</p>\r\n</div>\r\n<div class=\"special designer-box rounded p-3\">\r\n    <p class=\"mb-4\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"repaired_item_name") || (depth0 != null ? lookupProperty(depth0,"repaired_item_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"repaired_item_name","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":44}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"repaired_item_serial_no") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":115}}})) != null ? stack1 : "")
    + "</p>\r\n    <p>"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"maximum_agreed_cost",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":7},"end":{"line":9,"column":49}}}))
    + ": "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"max_agreed_cost") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":9,"column":51},"end":{"line":9,"column":107}}}))
    + "</p>\r\n</div>\r\n<div class=\"info designer-box rounded\">\r\n    <div class=\"row\">\r\n        <div class=\"col-md-10\">\r\n            <h3>"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"repair_{number}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":57}}}))
    + "</h3>\r\n        </div>\r\n        <div class=\"col-md-2\">\r\n            <button class=\"btn btn-info btn-block designer-box rounded\" data-ui=\"logs\"><i class=\"fa fa-eye\"></i></button>\r\n        </div>\r\n    </div>\r\n    <div class=\"row\">\r\n        <label class=\"control-label vertical-align col-md-5\">\r\n            "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"date_in",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":42}}}))
    + ":\r\n        </label>\r\n        <div class=\"col-md-7 \">\r\n            "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_created") : depth0),"LL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":49}}}))
    + "\r\n        </div>\r\n    </div>\r\n    <div class=\"row\">\r\n        <label class=\"control-label vertical-align col-md-5\">\r\n            "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"date_done",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":44}}}))
    + ":\r\n        </label>\r\n        <div class=\"col-md-7 \">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_done") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n    <div class=\"row\">\r\n        <label class=\"control-label vertical-align col-md-5\">\r\n            "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"status",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":42,"column":41}}}))
    + ":\r\n        </label>\r\n        <div class=\"col-md-7 \">\r\n            <span class=\"label label-"
    + alias4(((helper = (helper = lookupProperty(helpers,"statusClass") || (depth0 != null ? lookupProperty(depth0,"statusClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusClass","hash":{},"data":data,"loc":{"start":{"line":45,"column":37},"end":{"line":45,"column":52}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"statusTitle") || (depth0 != null ? lookupProperty(depth0,"statusTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusTitle","hash":{},"data":data,"loc":{"start":{"line":45,"column":54},"end":{"line":45,"column":69}}}) : helper)))
    + "</span>\r\n        </div>\r\n    </div>\r\n    <div class=\"row\">\r\n        <label class=\"control-label vertical-align col-md-5\">\r\n            "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"created_by",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":50,"column":45}}}))
    + ":\r\n        </label>\r\n        <div class=\"col-md-7 \">\r\n            "
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"created_by") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"product-list designer-box rounded product-list-checkout\" data-region=\"product-list-and-search\"></div>\r\n<div class=\"product-total full-child-height\" data-region=\"product-totals\"></div>\r\n<div class=\"keypad designer-box rounded\" data-region=\"keypad\"></div>\r\n<div class=\"pay full-child-height\" data-ui=\"checkoutButtons\">\r\n    <button class=\"btn btn-info btn-block designer-box rounded\" data-action=\"back\" data-ui=\"back\">"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":61,"column":98},"end":{"line":61,"column":125}}}))
    + "</button>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_receipt_printer") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":62,"column":4},"end":{"line":69,"column":11}}})) != null ? stack1 : "")
    + "    <button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditable") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":70,"column":53}}})) != null ? stack1 : "")
    + " class=\"btn btn-success btn-block designer-box rounded\" data-action=\"finish\" data-ui=\"finish\"> "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"finish",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":70,"column":148},"end":{"line":70,"column":177}}}))
    + "</button>\r\n</div>\r\n\r\n<div class=\"products-description designer-box rounded\" data-region=\"product-description\" style=\"display: none;\" data-ui=\"product-description\"></div>\r\n\r\n<div class=\"hidden\" data-region=\"popup\"></div>\r\n";
},"useData":true});