var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_receipt_copy",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":22},"end":{"line":17,"column":60}}}))
    + "</span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":22},"end":{"line":19,"column":55}}}))
    + "</span>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showKitchenReceipt") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <button class=\"btn btn-light designer-box rounded\">\r\n            <div class=\"loader-wrapper\" data-name=\"loader-print\">\r\n                <div class=\"loader\"></div>\r\n            </div>\r\n            <div data-action=\"receipt\">\r\n                <div>\r\n                    <i class=\"fas fa-receipt\"></i>\r\n                </div>\r\n                <br>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_ccv_payment") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":59,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n        </button>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"btn btn-light designer-box rounded\">\r\n                <div class=\"loader-wrapper\" data-name=\"loader-kitchen\">\r\n                    <div class=\"loader\"></div>\r\n                </div>\r\n                <div data-action=\"kitchen\">\r\n                    <div>\r\n                        <i class=\"fas fa-hat-chef\"></i>\r\n                    </div>\r\n                    <br>\r\n\r\n                    <span>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"print_kitchen_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":26},"end":{"line":37,"column":70}}}))
    + "</span>\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_ccv_payment") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":41,"column":31}}})) != null ? stack1 : "")
    + "                </div>\r\n            </button>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                        <span>&</span>\r\n                        <span>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":40,"column":30},"end":{"line":40,"column":66}}}))
    + "</span>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt_copy",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":56,"column":26},"end":{"line":56,"column":67}}}))
    + "</span>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":58,"column":26},"end":{"line":58,"column":62}}}))
    + "</span>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmailFeatureAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":74,"column":15}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"btn btn-light designer-box rounded\" data-action=\"email\">\r\n                <div class=\"loader-wrapper\" data-name=\"loader-email\">\r\n                    <div class=\"loader\"></div>\r\n                </div>\r\n                <i class=\"fas fa-envelope\"></i>\r\n                <br>\r\n                <span>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"email_invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":72,"column":22},"end":{"line":72,"column":58}}}))
    + "</span>\r\n            </button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"payment-info-left\">\r\n    <i class=\"far fa-5x fa-check-circle\"></i><br />\r\n</div>\r\n\r\n<div class=\"payment-info-right\" data-region=\"payments\">\r\n\r\n</div>\r\n\r\n<div class=\"btn-row\">\r\n    <button class=\"btn btn-light designer-box rounded\">\r\n        <div data-action=\"no-receipt\">\r\n            <div class=\"no-receipt-icons\">\r\n                <i class=\"fal fa-ban \"></i>\r\n            </div>\r\n            <br>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_ccv_payment") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </button>\r\n\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_cups_printer") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":62,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_invoice_payment") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":4},"end":{"line":75,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"useData":true});