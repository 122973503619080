var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <br/><b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"relation_data") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</b>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\r\n    #"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":2,"column":5},"end":{"line":2,"column":15}}}) : helper)))
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_anonymous") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "    <br/>"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_purchased") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":6,"column":9},"end":{"line":6,"column":49}}}))
    + "\r\n</td>\r\n<td  class=\"text-right\">\r\n    "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"value_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":52}}}))
    + "\r\n</td>\r\n<td class=\"text-center\">\r\n    <span class=\"label label-"
    + alias4(((helper = (helper = lookupProperty(helpers,"statusClass") || (depth0 != null ? lookupProperty(depth0,"statusClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusClass","hash":{},"data":data,"loc":{"start":{"line":12,"column":29},"end":{"line":12,"column":44}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"statusTitle") || (depth0 != null ? lookupProperty(depth0,"statusTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusTitle","hash":{},"data":data,"loc":{"start":{"line":12,"column":46},"end":{"line":12,"column":61}}}) : helper)))
    + "</span>\r\n</td>\r\n<td  class=\"text-left\">\r\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"descriptionShort") || (depth0 != null ? lookupProperty(depth0,"descriptionShort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"descriptionShort","hash":{},"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":24}}}) : helper)))
    + "\r\n</td>\r\n<td class=\"text-center\">\r\n    <button class=\"btn btn-regular btn-primary\" data-action=\"details\">\r\n        "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"details",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":38}}}))
    + "\r\n    </button>\r\n</td>\r\n";
},"useData":true});