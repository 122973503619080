var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n<div class=\"progress\">\r\n    <div class=\"progress-bar progress-bar-danger progress-bar-striped active\" role=\"progressbar\"\r\n         style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"error_per") || (depth0 != null ? lookupProperty(depth0,"error_per") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"error_per","hash":{},"data":data,"loc":{"start":{"line":4,"column":23},"end":{"line":4,"column":36}}}) : helper)))
    + "%\">\r\n        <span class=\"sr-only\" data-ui=\"errorNo\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"error") || (depth0 != null ? lookupProperty(depth0,"error") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"error","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":59}}}) : helper)))
    + "</span>\r\n    </div>\r\n    <div class=\"progress-bar progress-bar-success progress-bar-striped active\" role=\"progressbar\"\r\n         style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"progress_per") || (depth0 != null ? lookupProperty(depth0,"progress_per") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"progress_per","hash":{},"data":data,"loc":{"start":{"line":8,"column":23},"end":{"line":8,"column":39}}}) : helper)))
    + "%\">\r\n        <span class=\"sr-only\" data-ui=\"doneNo\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"done") || (depth0 != null ? lookupProperty(depth0,"done") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"done","hash":{},"data":data,"loc":{"start":{"line":9,"column":47},"end":{"line":9,"column":57}}}) : helper)))
    + "</span>\r\n    </div>\r\n</div>\r\n\r\n<table class=\"table table-bordered\">\r\n    <thead>\r\n    <tr>\r\n        <th>"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"barcode",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":42}}}))
    + "</th>\r\n        <th>"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"product_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":47}}}))
    + "</th>\r\n        <th>"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"status",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":41}}}))
    + ": <select class=\"form-control\" data-ui=\"status-select\"></select></th>\r\n    </tr>\r\n    </thead>\r\n    <tbody></tbody>\r\n</table>";
},"useData":true});