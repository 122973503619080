var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"weight",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":33}}}))
    + "</label>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"measurements",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":39}}}))
    + "</label>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label class=\"control-label\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"needs_weight") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"size\">\r\n    <div class=\"quantity\" data-region=\"length\"></div>\r\n    <div class=\"times\">\r\n        <i class=\"far fa-times\"></i>\r\n    </div>\r\n    <div class=\"price\" data-region=\"price\"></div>\r\n    <div class=\"total\">\r\n        <i class=\"far fa-equals pull-left\"></i>&nbsp;<span class=\"pull-right\" data-ui=\"total-price\">€ 0,00</span>\r\n    </div>\r\n</div>\r\n";
},"useData":true});