var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"create-repair-container\">\r\n    <div class=\"form-wrapper designer-box rounded container-fluid\">\r\n        <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"create_a_new_repair",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":54}}}))
    + "</h2>\r\n        <hr>\r\n        <form onsubmit=\"return false;\" method=\"post\">\r\n            <div class=\"form-group row\">\r\n                <div class=\"col-md-2\">\r\n                    <label class=\"control-label\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":55}}}))
    + ":\r\n                    </label>\r\n                </div>\r\n\r\n                <div class=\"form-control-static col-md-8\">\r\n                    <div data-region=\"customer-search-region\"></div>\r\n                    <input type=\"hidden\" name=\"relation_data_id\">\r\n                </div>\r\n\r\n                <div class=\"col-md-2\">\r\n                    <a href=\"javascript:void(0);\" data-action=\"create-customer\" class=\"btn btn-default btn-regular pull-right\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"create_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":20,"column":62}}}))
    + "\r\n                    </a>\r\n                </div>\r\n            </div>\r\n            <div class=\"form-group row\">\r\n                <div class=\"col-md-2\">\r\n                    <label class=\"control-label\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"product",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":27,"column":54}}}))
    + ":\r\n                    </label>\r\n                </div>\r\n\r\n                <div class=\"form-control-static col-md-10\">\r\n                    <div id=\"select-a-product\" data-region=\"product-search-region\"></div>\r\n                    <input type=\"hidden\" name=\"repaired_item_name\">\r\n                </div>\r\n            </div>\r\n\r\n            <div class=\"form-group row\">\r\n                <div class=\"col-md-2\">\r\n                    <label class=\"control-label\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"product_serial",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":61}}}))
    + ":\r\n                    </label>\r\n                </div>\r\n\r\n                <div class=\"form-control-static col-md-10\">\r\n                    <input type=\"text\" name=\"repaired_item_serial_no\" class=\"form-control\" id=\"product-serial\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"product_serial",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":124},"end":{"line":45,"column":161}}}))
    + "\">\r\n                </div>\r\n            </div>\r\n\r\n            <div class=\"form-group row\">\r\n                <div class=\"col-md-2\">\r\n                    <label class=\"control-label\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"issue",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":52,"column":24},"end":{"line":52,"column":52}}}))
    + ":\r\n                    </label>\r\n                </div>\r\n\r\n                <div class=\"form-control-static col-md-10\">\r\n                    <textarea class=\"form-control\" name=\"description\" id=\"description\" rows=\"10\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"what_is_the_issue",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":110},"end":{"line":57,"column":150}}}))
    + "\"></textarea>\r\n                </div>\r\n            </div>\r\n\r\n            <div class=\"form-group row\">\r\n                <div class=\"col-md-2\">\r\n                    <label class=\"control-label\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"due_date",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":64,"column":24},"end":{"line":64,"column":55}}}))
    + ":\r\n                    </label>\r\n                </div>\r\n\r\n                <div class=\"form-control-static col-md-10\">\r\n                    <h4>\r\n                        <a href=\"javascript:void(0);\" class=\"text-dark\" id=\"day-picker\">\r\n                            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"select_a_date",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":71,"column":28},"end":{"line":71,"column":64}}}))
    + ": <span id=\"selected-date\"></span>\r\n                            <i class=\"fa fa-caret-down\"></i>\r\n                        </a>\r\n                    </h4>\r\n                    <input type=\"hidden\" name=\"agreed_delivery_date\">\r\n                </div>\r\n            </div>\r\n            <div class=\"form-group row\">\r\n                <div class=\"col-md-2\">\r\n                    <label class=\"control-label\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"maximum_agreed_cost",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":81,"column":24},"end":{"line":81,"column":66}}}))
    + ":\r\n                    </label>\r\n                </div>\r\n\r\n                <div class=\"form-control-static col-md-10\">\r\n                    <input type=\"number\" name=\"max_agreed_cost\" class=\"form-control\" id=\"maximum-agreed-cost\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"maximum_agreed_cost",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":86,"column":123},"end":{"line":86,"column":165}}}))
    + "\">\r\n                </div>\r\n            </div>\r\n            <div class=\"form-group row\">\r\n                <div class=\"col-md-2\">\r\n                    <label class=\"control-label\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"handling_employee",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":92,"column":24},"end":{"line":92,"column":64}}}))
    + ":\r\n                    </label>\r\n                </div>\r\n\r\n                <div class=\"form-control-static col-md-10\">\r\n                    <div data-region=\"employee-search-region\"></div>\r\n                    <input type=\"hidden\" name=\"handling_employee_id\">\r\n                </div>\r\n            </div>\r\n            <div class=\"form-group row mt-5\">\r\n                <div class=\"col-md-6\">\r\n                    <a class=\"btn btn-default btn-regular pull-left\" data-action=\"back\">\r\n                        <i class=\"fas fa-arrow-left\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"go_back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":104,"column":58},"end":{"line":104,"column":88}}}))
    + "\r\n                    </a>\r\n                </div>\r\n                <div class=\"col-md-6\">\r\n                    <a class=\"btn btn-default btn-regular pull-right\" data-action=\"create-repair\">\r\n                        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"create_repair",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":109,"column":24},"end":{"line":109,"column":60}}}))
    + "\r\n                    </a>\r\n                </div>\r\n            </div>\r\n        </form>\r\n    </div>\r\n</div>\r\n";
},"useData":true});