var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span data-ui=\"discount-header\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"discount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":63}}}))
    + "</span>\r\n<span data-ui=\"discount-set-header\" style=\"display: none;\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"set_discount_for_all",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":59},"end":{"line":2,"column":102}}}))
    + ":</span>\r\n<b class=\"text-danger\" data-ui=\"discount-amount\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"discount") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":3,"column":49},"end":{"line":3,"column":97}}}))
    + "</b>\r\n<div data-region=\"popupDiscount\"></div>";
},"useData":true});