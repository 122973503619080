var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\r\n    <div class=\"alert alert-warning\">\r\n\r\n        <strong>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"warning_exclamation",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":58}}}))
    + "</strong> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"all_items_from_the_order_are_already_processed_you_have_to_handle_the_rest_of_the_order_from_the_backoffice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":68},"end":{"line":5,"column":198}}}))
    + "\r\n    </div>\r\n    <button class=\"dialog--close btn btn-danger\" data-ui=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\">\r\n    <div data-ui=\"message\" class=\"alert message--alert hidden\"></div>\r\n</div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pickup_at_the_store",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":50}}}))
    + " #"
    + alias2(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":14,"column":52},"end":{"line":14,"column":62}}}) : helper)))
    + "</h2>\r\n\r\n    <button class=\"dialog--close btn btn-danger\" data-ui=\"close\">\r\n        <i class=\"fa fa-times\"></i>\r\n    </button>\r\n</div>\r\n<div class=\"dialog--content\">\r\n    <div data-ui=\"content\"></div>\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"mode") : depth0),"==",(depth0 != null ? lookupProperty(depth0,"MODE_EDIT") : depth0),{"name":"common$compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":48,"column":23}}})) != null ? stack1 : "")
    + "</div>\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"mode") : depth0),"==",(depth0 != null ? lookupProperty(depth0,"MODE_EDIT") : depth0),{"name":"common$compare","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":50,"column":0},"end":{"line":70,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h4>\r\n            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"shipping_method",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":50}}}))
    + ":\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multipleMethods") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":39,"column":19}}})) != null ? stack1 : "")
    + "        </h4>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <select class=\"form-control form-control-inline\" data-ui=\"method\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"methods") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":29,"column":29}}})) != null ? stack1 : "")
    + "                </select>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":28,"column":39},"end":{"line":28,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":28,"column":47},"end":{"line":28,"column":55}}}) : helper)))
    + "</option>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":38,"column":23}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"methodName") || (depth0 != null ? lookupProperty(depth0,"methodName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"methodName","hash":{},"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":33,"column":34}}}) : helper)))
    + "\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"label label-danger\">\r\n                        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_pickup_methods_available",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":36,"column":74}}}))
    + "\r\n                    </span>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasReceiptPrinter") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "        <label class=\"form-control\">\r\n            <input type=\"checkbox\"  checked data-ui=\"print-checkbox\">\r\n            <span class=\"h4\"> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_confirmation",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":30},"end":{"line":45,"column":71}}}))
    + "</span>\r\n        </label>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"dialog--footer\">\r\n        <button data-ui=\"confirm\" class=\"btn btn-lg btn-success\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":65},"end":{"line":52,"column":106}}})) != null ? stack1 : "")
    + ">\r\n            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"collect",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":53,"column":42}}}))
    + "\r\n        </button>\r\n    </div>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"dialog--footer three-buttons\">\r\n        <button data-ui=\"back\" class=\"btn btn-lg btn-default\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":62},"end":{"line":58,"column":103}}})) != null ? stack1 : "")
    + ">\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"back_to_collection_screen",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":59,"column":12},"end":{"line":59,"column":60}}}))
    + "\r\n        </button>\r\n        <button data-ui=\"deliver\" class=\"btn btn-lg btn-success\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":65},"end":{"line":61,"column":106}}})) != null ? stack1 : "")
    + ">\r\n            <i class=\"fas fa-hand-holding-box\"></i>\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"finalize_collection",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":63,"column":54}}}))
    + "\r\n        </button>\r\n        <button data-ui=\"prepare\" class=\"btn btn-lg btn-default\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":65},"end":{"line":65,"column":106}}})) != null ? stack1 : "")
    + ">\r\n            <i class=\"far fa-box\"></i>\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"ready_for_customer_pickup",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":67,"column":60}}}))
    + "\r\n        </button>\r\n    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmpty") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":71,"column":7}}})) != null ? stack1 : "");
},"useData":true});