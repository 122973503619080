var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <select class=\"form-control form-control-inline\" data-ui=\"method\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"methods") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":16,"column":25}}})) != null ? stack1 : "")
    + "            </select>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":35},"end":{"line":15,"column":41}}}) : helper)))
    + "\" "
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"shippingMethodId") : depths[1]),"==",(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"common$compare","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":43},"end":{"line":15,"column":117}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":15,"column":118},"end":{"line":15,"column":126}}}) : helper)))
    + "</option>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "selected";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"methodName") || (depth0 != null ? lookupProperty(depth0,"methodName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"methodName","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":30}}}) : helper)))
    + "\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"label label-danger\">\r\n                    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_pickup_methods_available",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":70}}}))
    + "\r\n                </span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"partial_delivery",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":47}}}))
    + "</h2>\r\n\r\n    <button class=\"dialog--close btn btn-danger\" data-ui=\"close\">\r\n        <i class=\"fa fa-times\"></i>\r\n    </button>\r\n</div>\r\n<div class=\"dialog--content\">\r\n    <div data-ui=\"content\"></div>\r\n    <h4>\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"shipping_method",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":46}}}))
    + ":\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multipleMethods") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + "    </h4>\r\n</div>\r\n<div class=\"dialog--footer two-buttons\">\r\n    <button data-ui=\"back\" class=\"btn btn-lg btn-default\">\r\n        <i class=\"fas fa-arrow-left\"></i>\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"uppercase_undo",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":45}}}))
    + "\r\n    </button>\r\n    <button data-ui=\"save\" class=\"btn btn-lg btn-success\">\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"confirm",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":35,"column":38}}}))
    + "\r\n    </button>\r\n</div>\r\n";
},"useData":true,"useDepths":true});