var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <th class=\"text-left\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"attributes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":34},"end":{"line":7,"column":67}}}))
    + "</th>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <th></th>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-vcenter table-striped\">\r\n    <thead>\r\n    <tr>\r\n        <th class=\"text-left\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"product_description",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":30},"end":{"line":4,"column":72}}}))
    + "</th>\r\n        <th class=\"text-right\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"price",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":59}}}))
    + "</th>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAttributes") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "        <th class=\"text-center\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"details",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":62}}}))
    + "</th>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_cups_printer") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "    </tr>\r\n    </thead>\r\n    <tbody></tbody>\r\n</table>";
},"useData":true});