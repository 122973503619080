var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"text-right\">\r\n    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"addonTotalWt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":56}}}))
    + "\r\n</span>";
},"useData":true});